// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';



import Download from '../views/home/Download.vue';

import My from '../views/my/My.vue';
import Abou from '../views/about/About.vue';
import Ne from '../views/new/New.vue';

import Newi from '../views/new/NewIn.vue';
import User from '../views/users/Users.vue';

import Meetin from '../views/meeting/Meeting.vue';
import MeetingD from '../views/meeting/MeetingDe.vue';
import MeetingLis from '../views/meeting/MeetingList.vue';
import Exper from  '../views/expert/Expert.vue';

import ExperD from  '../views/expert/ExpertDe.vue';
import ExpertDeJ from  '../views/expert/ExpertDeJg.vue';
import Financin from '../views/financing/Financing.vue';
import FinancingD from '../views/financing/FinancingDe.vue';
import Wor from '../views/work/Work.vue';
import WorkD from '../views/work/WorkDe.vue';

import WorkDpi from '../views/work/WorkDePi.vue';

import Cooperatio from '../views/cooperation/Cooperation.vue';
import CooperationD from '../views/cooperation/CooperationDe.vue';


import Merchan from '../views/cooperation/Merchant.vue';
import MerchantD from '../views/cooperation/MerchantDe.vue';

import U from '../views/us/Us.vue';
import Clou from '../views/cloud/Cloud.vue';

import MeetingNewDe from '../views/meeting/MeetingNewDe.vue';

import CloudNewIn from '../views/cloud/CloudNewIn.vue';

import MeetingNewList from '../views/meeting/MeetingNewList.vue';

import Login from '../views/login/Login.vue';

import MyRuhui from '../views/my/MyRuhui.vue';

import MyMessage from '../views/my/MyMessage.vue';

import MyMeeting from '../views/my/MyMeeting.vue';

import MyMeetingFen from '../views/my/MyMeetingFen.vue';

import MyCooperation from "../views/my/MyCooperation.vue";

import MyMerchant from "../views/my/MyMerchant.vue";

import MyFinancing from '../views/my/MyFinancing.vue';
import MyMeans from '../views/my/MyMeans.vue';
import MySecurity from '../views/my/MySecurity.vue';

import Test from '../views/test/Test.vue';
import YView from '../views/YView.vue';
import MyWriteOff from '../views/my/MyWriteOff.vue';

import MyCoupon from '../views/my/MyCoupon.vue';

import Aboutin from '../views/about/ChildComps/Aboutin.vue';


import Examination from '../views/examination/Examination.vue';


import Search from '../views/examination/Search.vue';

import Search2 from '../views/examination/Search2.vue';
import Collect from '../views/examination/Collect.vue';

import Know from '../views/examination/Know.vue';


import BigData from '../views/bigdata/BigData.vue';


const routes = [
  {
    path: '/',
    name: 'DefaultHome',
    component: HomeView
  },
  // {
  //   path: '/yView',
  //   name: 'YView',
  //   component: YView
  // },
  // {
  //   path: '/aboutView',
  //   name: 'AboutView',
  //   component: AboutView
  // },
  // // path: '/home/:id',
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/download',
  //   name: 'Down',
  //   component: Download
  // },
  // {
  //   path: '/my',
  //   name: 'Myin',
  //   component: My,

  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: Abou,

  // },
  // {
  //   path: '/new',
  //   name: 'New',
  //   component: Ne,

  // },
  // {
  //   path: '/newin',
  //   name: 'Newin',
  //   component: Newi,

  // },
  // {
  //   path: '/users',
  //   name:'Users',
  //   component: User,

  // },
  // {
  //   path: '/meeting',
  //   name:'Meeting',
  //   component: Meetin,

  // },
  // {
  //   path: '/meetingDe',
  //   name:'MeetingDe',
  //   component: MeetingD,

  // },
  // {
  //   path: '/meetingList',
  //   name:'MeetingList',
  //   component: MeetingLis,

  // },
  // {
  //   path: '/expert',
  //   name:'Expert',
  //   component: Exper,

  // },
  // {
  //   path: '/experDe',
  //   name:'ExpertDe',
  //   component: ExperD,

  // },

  //  {
  //     path: '/expertDeJg',
  //     name:'ExpertDeJg',
  //     component: ExpertDeJ,
  // },
  // {
  //     path: '/financing',
  //     name:'Financing',
  //     component: Financin,
  // },
  // {
  //     path: '/financingDe',
  //     name:'FinancingDe',
  //     component: FinancingD,
  // },
  // {
  //     path: '/work',
  //     name:'Work',
  //     component: Wor,
  // },

  {
      path: '/workDe',
      name:'WorkDe',
      component: WorkD,
  },
  {
      path: '/workDepi',
      name:'WorkDepi',
      component: WorkDpi,
  },


  // {
  //     path: '/cooperation',
  //     name:'Cooperation',
  //     component: Cooperatio,
  // },
  // {
  //     path: '/cooperationDe',
  //     name:'CooperationDe',
  //     component: CooperationD,
  // },

  // {
  //     path: '/merchant',
  //     name:'Merchant',
  //     component: Merchan,
  // },
  // {
  //     path: '/merchantDe',
  //     name:'MerchantDe',
  //     component: MerchantD,
  // },
  // {
  //     path: '/us',
  //     name:'Us',
  //     component: U,
  // },
  // {
  //     path: '/cloud',
  //     name:'Cloud',
  //     component: Clou,
  //     // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  // {
  //     path: '/meetingNewDe',
  //     name:'MeetingNewDe',
  //     component: MeetingNewDe,
  //     // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  // {
  //     path: '/cloudNewIn',
  //     name:'CloudNewIn',
  //     component: CloudNewIn,
  // },
  // {
  //     path: '/meetingNewList',
  //     name:'MeetingNewList',
  //     component: MeetingNewList,
  // },
  // {
  //     path: '/login',
  //     name:'Login',
  //     component: Login,
  // },

  // {
  //     path: '/myRuhui',
  //     name:'MyRuhui',
  //     component: MyRuhui,
  // },

  // {
  //     path: '/myMessage',
  //     name:'MyMessage',
  //     component: MyMessage,
  // },
  // {
  //   path: '/myMeeting',
  //   name:'MyMeeting',
  //   component: MyMeeting,
  // },
  // {
  //   path: '/myMeetingFen',
  //   name:'MyMeetingFen',
  //   component: MyMeetingFen,
  // },
  // {
  //   path: '/myCooperation',
  //   name:'MyCooperation',
  //   component: MyCooperation,
  // },
  // {
  //   path: '/myMerchant',
  //   name:'MyMerchant',
  //   component: MyMerchant,
  // },
  // {
  //   path: '/myFinancing',
  //   name:'MyFinancing',
  //   component: MyFinancing,
  // },
  // {
  //   path: '/myMeans',
  //   name:'MyMeans',
  //   component: MyMeans,
  // },
  // {
  //   path: '/mySecurity',
  //   name:'MySecurity',
  //   component: MySecurity,
  // },
  // {
  //   path: '/test',
  //   name:'Test',
  //   component: Test,
  // },
  // {
  //   path: '/myWriteOff',
  //   name:'MyWriteOff',
  //   component: MyWriteOff,
  // },
  // {
  //   path: '/myCoupon',
  //   name:'MyCoupon',
  //   component: MyCoupon,
  // },
  // {
  //   path: '/aboutin',
  //   name:'Aboutin',
  //   component: Aboutin,
  // },

  {
    path: '/examination',
    name:'Examination',
    component: Examination,
  },
  {
    path: '/search',
    name:'Search',
    component: Search,
  },
  {
    path: '/search2',
    name:'Search2',
    component: Search2,
  },

  {
    path: '/collect',
    name:'Collect',
    component: Collect,
  },
  {
    path: '/know',
    name:'Know',
    component: Know,
  },

  {
    path: '/bigData',
    name:'BigData',
    component: BigData,
  },
























  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

// const router = new VueRouter({
//  mode:'hash',
//  routes: routes // 只写静态路由
// })
const routerHistory = createWebHashHistory();
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: routerHistory,
   // history:createWebHashHistory(process.env.BASE_URL),
     // history: createWebHashHistory(),
   // devServer:{historyApiFallback: true},
  routes:routes
})

export default router
