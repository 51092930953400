<template>
  <div>
    <div v-for="(item,index) in know">
      <div class="l-one" v-if="item.is_ok==true || type==0" >

        <div class="img" @click="goTo('know',item.id)">
          <img :src="'http://viki.js-exp.com/public/'+item.fimage" />
        </div>
        <div class="l-left" @click="goTo('know',item.id)">
          <div class="l-nr">
            <div class="l-n-bt">{{item.name}}</div>
            <div class="l-n-nr">{{item.jianjie}}</div>
            <div class="lll">
              <div class="lll-list pi1"><img src="../../../assets/my/yanjing.png" />{{item.number}}</div>
              <div class="lll-list pi1"><img src="../../../assets/my/shijian1.png" />{{item.time}}</div>
              <div class="lll-list pi2"><img src="../../../assets/my/yanjing2.png" />{{item.number}}</div>
              <div class="lll-list pi2"><img src="../../../assets/my/shijian2.png" />{{item.time}}</div>
            </div>
            <div class="zxr">
              <span>最新查询人</span>
              <div class="pepolist">
                <div class="pepo" v-for="item1 in item.jsaccessrecords">
                  <img :src="'http://viki.js-exp.com/public'+item1.jsusers.avatar" />
                  <div>{{item1.jsusers.nickname}}</div>
                </div>
                <div class="pepo" v-for="item1 in item.jsaccessrecords">
                  <img :src="'http://viki.js-exp.com/public'+item1.jsusers.avatar" />
                  <div>{{item1.jsusers.nickname}}</div>
                </div>
                <div class="pepo" v-for="item1 in item.jsaccessrecords">
                  <img :src="'http://viki.js-exp.com/public'+item1.jsusers.avatar" />
                  <div>{{item1.jsusers.nickname}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="l-right shoucang">
          <img v-if='item.is_ok==false' @click="shoucang(item.id,index)" src="../../../assets/images/wsc.png" />
          <img v-if='item.is_ok==true' @click="qxshoucang(item.id,index)" src="../../../assets/images/ysc.png" />
        </div>
        <div class="l-right " @click="goTo('know',item.id)">
          <el-button type="primary">查看详情</el-button>
        </div>
      </div>
    </div>


    <page :items='know1.items'
          :litems='know1.litems'
          :total="know2.total"
          :per_page="know2.per_page"
          :current_page="know2.current_page"
          :last_page="know2.last_page"
          :listcount='listcount'
          @pageye="pageye($event)"
          ></page>

  </div>
</template>


<script>
  import {
    useRouter
  } from 'vue-router';
  import {
    getshoucang
  } from 'network/examination.js';
  import Page from "components/content/page/Page.vue";

  export default {
    name: 'List',
    data() {
      return {}
    },
    props: ["know", "type",'know1','know2'],
    setup() {
      //跳转方法
      const router = useRouter();
      var that = this;
      const goTo = (path, fl, lx) => {
        if (lx == 1) {
          var query = {
            fl: fl
          }
        } else if (lx == 2) {
          sessionStorage.setItem("sousuo", fl); //存储
          var query = {
            sousuo: fl
          }
        } else {
          var query = {
            id: fl
          }
        }
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
    mounted() {
      console.log(this.know);
      console.log('111');
    },
    methods: {
      pageye(page){
        // console.log(page);
        // alert(page);

         this.$emit('page',page);

      },
      listcount(page) {
        this.page = page;
        // alert(page);
        this.$emit('page',page);
      },

      shoucang(id, index) {
        this.know[index]['is_ok'] = true;
        this.$notify({
          type: 'success',
          message: '收藏成功',
          offset: 60
        });
        var that = this;
        var data = {
          id: id,
          type: 0
        };
        getshoucang(data).then(res => {}).catch(err => {})
      },
      qxshoucang(id, index) {
        this.know[index]['is_ok'] = false;
        this.$notify({
          type: 'success',
          message: '取消收藏成功',
          offset: 60
        });
        var that = this;
        var data = {
          id: id,
          type: 1
        };
        getshoucang(data).then(res => {}).catch(err => {})
      },
    },
    components: {
      Page,
    },
  }
</script>

<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    min-height: 100vh;

    .c-all {
      width: var(--allWidth);
      width: 98%;
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 84%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 0 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          .zhongjian {
            display: inline-block;
            margin-left: 16rem;

            div {
              display: inline-block;
              width: 4.875rem;
              padding: 1.275rem 0rem;
              text-align: center;
              margin: 0 2rem;
              cursor: pointer;
            }

            .zs {
              border-bottom: 0.125rem solid #2E9BD6;
            }
          }

          span {
            vertical-align: middle;
          }
        }



      }
    }

  }

  .shoucang {
    display: flex !important;
    height: 0.625rem;

    img {
      width: 1.75rem !important;
      margin-right: 0.625rem !important;
    }

  }

  .list {

    // padding: 1.875rem;
    .l-one {
      border-bottom: 0.0625rem solid #E8E8E8;
      padding: 0.8rem 0.8rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      align-items: flex-start;

      .l-left {
        width: 100%;
        display: flex;

        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          margin-right: 0.625rem;
        }

        .l-nr {
          .l-n-bt {
            font-weight: bold;
            margin-bottom: 0.75rem;
            margin-top: 0.5rem;
            font-size: 1.2rem;
          }

          .l-n-nr {
            // white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            font-size: 0.8rem;
            // color: #888888;
            color: #000;
          }
        }
      }

      .shoucang {
        position: absolute;
        top: 1.50rem;
        right: 1.50rem;
        // display: inline-block!important;
      }

      .l-right {
        position: absolute;
        bottom: 0.825rem;
        right: 0.825rem;

        display: flex;
        align-items: center;
        color: #999999;

        img {
          width: 1.75rem;
          margin-right: 0.625rem;
        }

      }
    }


  }



  @media screen and (max-width: 1300px) {
    .l-one{
      background: #fff;
    }
    .l-nr{
      width: 100%!important;
    }
    .l-right{
      bottom: 2.825rem!important;
      right: 1.225rem!important;
    }
    .zxr{
      width: 64%!important;
      overflow-x: auto!important;
    }
    .l-n-bt{
        font-size: 1.6rem;
        margin: 0.2rem 0;
    }
    .l-left{
      margin:0!important;
    }
    .l-one{
      padding: 0.8rem!important;
      .img{
        width:100%!important;
      }
    }
    .shoucang{
      right:1.2rem!important;
      top: 1.2rem!important;
    }
    .c-all {
      width: 95% !important;
      margin: 0 auto;
      flex-wrap: wrap;
    }

    .c-right {
      width: 100% !important;
      margin: 0 auto !important;
    }

    .lefts {
      width: 100%;
    }

    .c-right {
      .center {
        margin: 0rem !important;
        padding: 0.75rem !important;
      }
    }

    .c-bt {
      display: flex;
    }

    .c-lt {
      padding: 0.65rem 0 !important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }

    .c-right {
      .c-top {
        padding: 0.8rem !important;

        .zhongjian {
          margin: 0 !important;
          padding: 0;
          display: flex !important;

          .z-one {
            padding: 0.675rem 0rem !important;
            width: 60% !important;
            font-size: 0.95rem !important;
            margin: 0 !important;
            margin-bottom: 0.625rem !important;
          }
        }
      }
    }

    .l-one {
      flex-wrap: wrap;

      .l-left {
        width: 100% !important;
      }

      .l-right {
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }



  }

  .lll {
    display: flex;
    align-items: center;
    margin-top: 0.425rem;

    .lll-list {
      display: flex;
      // align-items: center;
      font-size: 0.8rem;
      margin-right: 0.825rem !important;
      // color: #888;
      color: #000;

      img {
        width: 0.8rem !important;
        height: 0.8rem !important;
        margin-right: 0.125rem !important;
      }
    }
  }

  .zxr {

    font-size: 0.7rem;
    margin-top: 0.425rem;

    .pepolist {
      display: flex;

      .pepo {
        text-align: center;
        margin-top: 0.625rem;
        margin-right: 0.625rem;
      }

      img {
        font-size: 0.7rem;
        width: 2rem !important;
        height: 2rem !important;
        margin-right: 0.125rem !important;
      }

    }

  }

  .pi2 {
    display: none !important;
  }

  .l-one:hover {
    // background: #064addd6;
    background: rgb(36 88 201 / 81%);
    color: #fff;

    .l-n-nr {
      color: #fff !important;
    }

    .lll-list {
      color: #fff !important;
    }

    .pi2 {
      display: inline-block !important;
    }

    .pi1 {
      display: none !important;
    }
  }

  .pi2 {
    display: none !important;
  }


  .img {
    width: 21%;

    img {
      width: 100%;
    }
  }

  .l-left {
    width: 77% !important;
    margin-left: 1.5%;
  }
</style>
