<template>
  <div class="lefts">

    <div class="top-title mobile"  @click="sure=!sure">
       <img class="ziliao" src="~assets/my/zlxx.png" />
       <span>菜单</span>
       <img class="imgss" v-show="sure==true" src="~assets/home/sjt.png" />
       <img class="imgss" v-show="sure==false" src="~assets/home/sjtf.png" />
     </div>


     <div class="mobile" v-show="sure==false">
       <block v-if="oneList.length>0" v-for="item in oneList" >
          <div class="top-bt" v-if="leftlist">
            <img src="~assets/my/zlxx.png" />
            <span>{{item.name}}</span>
          </div>
          <div class="top-cen " v-for="item1 in item['jsknowlist']"  @click="goTo('/',item1.id)"  :class="topleft==item1.id?'bs':''">
            <span>{{item1.name}}</span>
            <img src="~assets/my/jt.png" class="rightdw " :class="topleft==item1.id?'xs':''" />
          </div>
       </block>
       <div class="top-cen " v-for="item in leftlist" @click="goTo('examination',item.id)"  :class="topleft==0?'bs':''">
         <span>{{item.jsexamination.name}}</span>
         <img src="~assets/my/jt.png" class="rightdw " :class="topleft==0?'xs':''" />
       </div>




       <block >
          <div class="top-bt">
            <img src="~assets/my/zlxx.png" />
            <span>批卷</span>
          </div>
          <div class="top-cen "  @click="goTo('examination',0,'1')"  :class="topleft=='a'?'bs':''">
            <span>点击批卷</span>
            <img src="~assets/my/jt.png" class="rightdw " :class="topleft=='a'?'xs':''" />
          </div>
       </block>
     <!--  <div class="top-cen " v-for="item in leftlist" @click="goTo('examination',item.id)"  :class="topleft==0?'bs':''">
         <span>{{item.jsexamination.name}}</span>
         <img src="~assets/my/jt.png" class="rightdw " :class="topleft==0?'xs':''" />
       </div> -->










    </div>




    <div class="ccs">

      <div class="top-bt"  v-if="leftlist.length>0">
        <img src="~assets/my/zlxx.png" />
        <span>1.考试</span>
      </div>
      <div v-for="item in leftlist" class="top-cen " @click="goTo('examination',item.id)" :class="topleft==item.id?'bs':''">
        <span>{{item.jsexamination.name}}</span>
        <img src="~assets/my/jt.png" class="rightdw " :class="topleft==item.id?'xs':''" />
      </div>
      <block v-if="oneList.length>0" v-for="item in oneList" >
          <div class="top-bt" v-if="oneList.length>0" >
            <img src="~assets/my/zlxx.png" />
            <span>{{item.name}}</span>
          </div>

          <div v-for="item1 in item['jsknowlist']" class="top-cen " @click="goTo('/',item1.id)" :class="topleft==item1.id?'bs':''">
            <span>{{item1.name}}</span>
            <img src="~assets/my/jt.png" class="rightdw " :class="topleft==item1.id?'xs':''" />
          </div>
      </block>

      <div class="top-bt"  v-if="types=='1'">
        <img src="~assets/my/zlxx.png" />
        <span>2.批卷</span>
      </div>
      <div class="top-cen " v-if="types=='1'" @click="goTo('examination',0,'1')" :class="v=='a'?'bs':''">
        <span>点击批卷</span>
        <img src="~assets/my/jt.png" class="rightdw " :class="topleft=='a'?'xs':''" />
      </div>



      <!-- <div class="top-bt">
        <img src="~assets/my/zlxx.png" />
        <span>1.集团公共服务</span>
      </div>
      <div class="top-cen " @click="goTo('my','')" :class="topleft==0?'bs':''">
        <span>法务条款</span>
        <img src="~assets/my/jt.png" class="rightdw " :class="topleft==0?'xs':''" />
      </div>
      <div class="top-cen " @click="goTo('my','')" >
        <span>关务服务</span>
        <img src="~assets/my/jt.png" class="rightdw "  />
      </div>
      <div class="top-cen " @click="goTo('my','')" >
        <span>快递条款</span>
        <img src="~assets/my/jt.png" class="rightdw "  />
      </div>



      <div class="top-bt">
        <img src="~assets/my/hylt.png" />
        <span>2.通讯录</span>
      </div>
      <div class="top-cen" @click="goTo('myMeeting','')" :class="topleft==1?'bs':''">
        <span>企业通讯录</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==1?'xs':''" />
      </div>
      <div class="top-cen" @click="goTo('myMeetingFen','')" :class="topleft==2?'bs':''">
        <span>个人通讯录</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==2?'xs':''" />
      </div> -->

      <!-- div class="top-bt">
        <img src="~assets/my/hz.png" />
        <span>合作交流</span>
      </div>
      <div class="top-cen" @click="goTo('myCooperation','')" :class="topleft==4?'bs':''">
        <span>技术成果库</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==4?'xs':''" />
      </div>
      <div class="top-cen" @click="goTo('myMerchant','')" :class="topleft==5?'bs':''">
        <span>需求商机</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==5?'xs':''" />
      </div>



      <div class="top-bt">
        <img src="~assets/my/tz.png" />
        <span>投融资</span>
      </div>
      <div class="top-cen" @click="goTo('myFinancing','')" :class="topleft==6?'bs':''">
        <span>项目管理</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==6?'xs':''" />
      </div>



      <div class="top-bt">
        <img src="~assets/my/zh.png" />
        <span>账号管理</span>
      </div>
      <div class="top-cen" @click="goTo('myMeans','')" :class="topleft==7?'bs':''">
        <span>个人资料</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==7?'xs':''" />
      </div>
      <div class="top-cen" @click="goTo('mySecurity','')" :class="topleft==8?'bs':''">
        <span>账号安全</span>
        <img src="~assets/my/jt.png" class="rightdw" :class="topleft==8?'xs':''" />
      </div> -->
      <!--
        <div class="top-cen" @click="goTo('myWriteOff','')" :class="topleft==9?'bs':''">
          <span>点击核销</span>
          <img src="~assets/my/jt.png" class="rightdw" :class="topleft==9?'xs':''" />
      </div> -->

    </div>








  </div>
</template>

<script>
  import {
    useRouter
  } from 'vue-router';
  import {
    getnew,
    getgrxx
  } from 'network/home.js';
 import {
    getJspaper
  } from 'network/examination.js';


  export default {
    name: 'MyLeft',
    data() {
      return {
        // oneList:[],
        sure: true,
        title: '我的资料',
        grxx: [],
        sousuo: '',
        // leftlist:[],
        // topleft:0,
        newfl: []
      }
    },
    props: ["top", 'qhs', 'sousu', 'nr','topleft', 'grxxff', 'grxx','leftlist','oneList','types'],
    setup() {
      //跳转方法
      const router = useRouter();
      // var sousuo=this.sousuo;
      // this.sousuo=this.sousu;
      var that = this;
      const goTo = (path, fl, lx) => {
        // return false;
        console.log(lx);
        console.log(fl, );
        if (lx == 1) {
          var query = {
            fl: fl,
            type:lx
          }
        } else if (lx == 2) {
          sessionStorage.setItem("sousuo", fl); //存储
          var query = {
            sousuo: fl
          }
        } else {
          var query = {
            id: fl
          }
        }




        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
    mounted() {
      console.log(this.leftlist);
      console.log(this.oneList);

      // if (this.topleft == 0) {
      //   this.title = '我的资料';
      // } else if (this.topleft == 1) {
      //   this.title = '会议报名记录';
      // } else if (this.topleft == 2) {
      //   this.title = '论坛报名记录';
      // } else if (this.topleft == 4) {
      //   this.title = '技术成果库';
      // } else if (this.topleft == 5) {
      //   this.title = '需求商机';
      // } else if (this.topleft == 6) {
      //   this.title = '项目管理';
      // } else if (this.topleft == 7) {
      //   this.title = '个人资料';
      // } else if (this.topleft == 8) {
      //   this.title = '账号安全';
      // }
      this.left();
    },
    methods: {
      left(){
        var that = this;
        var data = {
        };
        getJspaper(data).then(res => {
          console.log(res);
          that.leftlist=res.data;
          that.topleft=res.data[0]['id'];
        }).catch(err => {})

      },

    }
  }
</script>

<style lang="scss" scoped="scoped">
  .lefts {
    width: 15%;
    background: #fff;
    margin-bottom: 3.125rem;

    .top-bt {
      display: flex;
      // justify-content: center;
      align-items: center;
      padding: 1.125rem 0.925rem;
      border-bottom: 0.0625rem solid #E6E6E6;
      font-weight: bold;

      img {
        margin-right: 0.375rem;
        width: 1.175rem;
      }
    }

    .top-cen {
      padding: 1.125rem 0.925rem;
      cursor: pointer;
      border-bottom: 0.0625rem solid #F0F0F0;

      span {
        width: 80%;
        display: inline-block;

        padding-left: 1.875rem;
        color: #787878;
      }

      img {
        width: 0.375rem;
      }

      position: relative;

      .rightdw {
        position: absolute;
        top: 50%;
        right: 0.625rem;
        transform: translate(0, -50%);
        display: none;
      }
    }

  }

  .bs {
    span {
      color: #2E9BD6 !important;
    }

  }

  .xs {
    display: block !important;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    .mobile {
      display: block;
    }

    .top-title {
      padding: 0.625rem;
      width: 100%;
      position: relative;

      .imgss {
        width: 1rem;
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translate(0%, -50%);
      }

      .ziliao {
        vertical-align: middle;
        width: 1.6rem;
        margin-right: 0.625rem;
      }

      span {
        vertical-align: middle;
      }

    }

    .ccs {
      display: none;
    }

    .lefts {
      margin-bottom: 0.5rem;
    }
  }
  .top-cen:hover{
    span{
      color: #2E9BD6!important;
    }

  }
  .ccs {
    position: sticky;
    top: 4rem;
  }
</style>
