<template>
  <div class="all">
    <div class="left">
      <div class="l-top tit">{{all.name}}</div>
      <div class="l-top zysx">考试人姓名：{{hjsuers.name}}
      </div>
      <div class="l-title">
        <span>当前总分：{{zongfen}}分</span>

      </div>
      <div class="l-hang"></div>
      <div class="l-content">
        <div v-for="(item,index) in tm"
          class="one bs" @click="goAnchor(index)"><a >{{radiogroup}}{{index+1}}</a></div>
          <!-- :href="'#a'+index" -->


      </div>
      <div class="l-hang"></div>
      <div class="l-hang"></div>
      <div class="anniu">
        <div class="tjsj" @click="tjsj()">批卷完成</div>
      </div>
    </div>
    <div class="right">
      <div class="r-top">
        一、题目
      </div>
      <div class="l-hang"></div>
      <block v-for="(item,index) in tm">
        <div class="r-two" :id="'a'+index">
          <div class="r-t-title">{{item.xuhao}}.{{item.name}}<span>（{{item.typename}}）</span></div>
          <div class="xuanxiang">
              <el-checkbox v-for="(item1,index1) in item['jsquestionin']"
              v-if="item.type=='1'"
                v-model="item1.checked"
               :value="true" :label="item1.id"
                >
               {{item1.name}}</el-checkbox>
              <el-radio  v-for="(item2,index1) in item['jsquestionin']"   v-model="item2.checked" :label="item2.id">
                <div class="xx"> {{item2.name}}</div>
              </el-radio>




            <el-input
              v-if="item.type=='3' || item.type=='4'"
              type="textarea"
              placeholder="请输入内容"
              v-model="item.zqda.jsquestionin_ids"
              maxlength="200"
             
              show-word-limit
            >
            </el-input>
            <view class="jeshi"  v-if="item.type=='3' || item.type=='4'">
              {{item.zqda.ckval}}
            </view>
            <view class="zz"></view>

          </div>

          <view class="ff">
            <span class="df">得分:</span>
            <el-input placeholder="请输入分数" @input="inputgb()" type="number" maxlength="100" v-model="item.fenshu" clearable  >
              <template slot="prepend">得分:</template>
            </el-input>



          </view>

        </div>
        <div class="l-hang"></div>

      </block>






    </div>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import {
    getfaboutshezhi1,
    getScore,
    getScorepijuan
  } from 'network/examination.js';



  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Journalism from 'components/content/journalism/Journalism.vue'
  import Notice from "components/content/notice/Notice.vue";
  import List from "components/content/list/List.vue";
  import screenfull from "screenfull";
  export default {
    name: 'New',
    data() {
      return {
        hjsuers:[],
        zongfen:0,
        ccc:true,
        timeObject:0,
        jid: 0,
        num: 0,
        msg: [],
        fullscreen: false, //全屏，fasle默认不是全屏，true为全屏 },
        fz: '',
        all: [],
        tm: {},

        radio: 3,
        checkList: [],

        v: 0,
        i: 1,
        bn: 'public/img/banner.387b0333.png',

        count: '', //倒计时
        seconds: 500, // 10天的秒数
        fullscreen: false,
      }
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.jiaojuan);
    },
    mounted() {
      var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
      url = '?' + url;

      console.log(url);
      var object = {};
      if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      {
        var str = url.substr(1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
        }
      }
      console.log(object);
      var lx = object['lx'];
      var id = object['id'];

      this.topleft = id;
      this.zt = lx;
      this.jid=id;
      this.listdata(id);


      // this.listdata(id);
      // this.handleFullScreen();

      //监听用户切屏
      let num = 0;
      let _this = this;



    },
    watch: {
      //如果$route.fullPath改变了，就是从当前页面离开了，就销毁掉全局的监听事件，不然这全局事件会一直存在，造成内存泄漏！
      '$route.fullPath': {
      }
    },

    setup() {
      const top = 8;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },

    methods: {

    handleCheckAllListChange(){

    },

      goAnchor(anchorName) {
           // 找到锚点
           let anchorElement = document.getElementById('a'+anchorName);
           console.log(anchorElement);
           // 如果对应id的锚点存在，就跳转到锚点
           if(anchorElement) { anchorElement.scrollIntoView(); }
      },

      jiaojuan(){
          // return false;
          var _this=this;
          if (!document.fullscreenElement) {
            // 非全屏状态
            //业务逻辑
            _this.$alert('由于您退出全屏，将强制交卷', '提示', {
              showClose: false,
              confirmButtonText: '我要交卷',
              callback: action => {
                _this.getScore1();
              }
            });

            return false;
          }

      },
      getScore1() {

       var that=this;
       var tms=that.tm;
       var zongfen=0;

       var fs='';
       // zongfen+= parseInt();
       for(var i=0;i<tms.length;i++){
         var one=tms[i]['zqda']['id']+'、'+tms[i]['fenshu'];
         fs+=one+',';
       }
       console.log(fs);

       var data = {
         id: that.jid,
         fs: fs,
       };
       console.log(data);
       getScorepijuan(data).then(res => {
         that.$message.success("交卷成功！")

         that.destroyed();
         that.$router.go(-1);

         console.log(res);

       }).catch(err => {})



        // this.$message.success("批卷完成！")

      },
      xuanzedx(index) {
        var tm = this.tm;
        console.log(tm[index]);

      },
      xuanzefx(index) {
        var tm = this.tm;
        console.log(tm[index]);

      },

      tjsj() {


        this.$confirm('是否批卷完成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'SUCCESS'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.getScore1();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消成功！'
          });
        });

      },
      destroyed() {

        document.removeEventListener('visibilitychange', () => {
          console.log("销毁页面隐藏")
        })

        document.removeEventListener('resize', () => {
          console.log("销毁页面隐藏")
        })

      },

      //浏览器进入全屏
      enterScreen() {
        let element = document.getElementById(
          'con_lf_top_div'
        ); //设置后就是 id==con_lf_top_div 的容器全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
        this.fullscreen = true
      },
      //浏览器退出全屏
      exitScreen() {

        if (document.exitFullscreen || document.documentElement.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

      },
      KeyDown(event) {
        if (event.keyCode === 122) {
          event.returnValue = false;
          this.toFullscreen();
        }
      },


      handleFullScreenChange() {
        if (document.fullscreenElement) {
          console.log("进入全屏模式");
        } else {
          console.log("退出全屏模式");
          this.handleFullScreen();
        }
      },


      handleFullScreen() {
        let element = document.documentElement;
        this.launchFullscreen(element);
      },


      launchFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.mozRequestFullscreen) {
          element.mozRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      },

      handleEscKey(event) {
        if (event.keyCode === 27) {
          console.log('1');
          return false;
        }
      },

      exitFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      },


      // handleFullScreen(){
      //   // 如果不允许进入全屏，发出不允许提示
      //    if (!screenfull.enabled) {
      //     this.$message("您的浏览器不能全屏");
      //     return false;
      //    }
      //    screenfull.toggle();
      //    this.$message.success("全屏啦");

      //    // if (!screenfull.isEnabled) {
      //    //     this.$message.info("您的浏览器版本过低，不支持全屏浏览");
      //    //     return false;
      //    // }
      //    // screenfull.toggle();

      // },

      // tjsj() {
      //   console.log(this.tm);
      // },
      listdata(id) {

        const router = useRouter();

        var that = this;
        var data = {
          id: id
        };

        this.jid=id;

        getfaboutshezhi1(data).then(res => {
          console.log(res);


          // that.jid = res.data.jid;

          that.all = res.data.jsexamination;
          that.hjsuers=res.data.hjsuers;
          that.seconds = that.all.fenzhong * 60;

          console.log(that.all);
          that.tm = res.data.jsexamination.jsquestion;
          that.jisuan();
          that.Time1() //调用定时器

        }).catch(err => {})


      },
      inputgb(){
         this.jisuan();
      },
      jisuan(){
        var that=this;
        var tms=that.tm;
        var zongfen=0;
        for(var i=0;i<tms.length;i++){
          zongfen+= parseInt(tms[i]['fenshu']);

        }

        that.zongfen=zongfen;
      },

      // 天 时 分 秒 格式化函数
      countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60))
        d = d < 10 ? "0" + d : d
        let h = parseInt(this.seconds / (60 * 60) % 24);
        h = h < 10 ? "0" + h : h
        let m = parseInt(this.seconds / 60 % 60);
        m = m < 10 ? "0" + m : m
        let s = parseInt(this.seconds % 60);
        s = s < 10 ? "0" + s : s
        // d + '天' +
        this.count = h + ':' + m + ':' + s;
        var that=this;
        if(h=='00' && m=='00' && s=='00'){
          //关闭定时器

          that.$alert('考试试卷到,请点击交卷', '提示', {
            showClose: false,
            confirmButtonText: '交卷',
            callback: action => {
              that.getScore1();
            }


          });
        }
      },
      //定时器没过1秒参数减1
      Time1() {
        // var that=this;
        // if(this.timeObject==0){
        //   this.timeObject=window.setInterval(function(){
        //       that.seconds -= 1
        //       that.countDown()
        //   },1000)

        // }

      },

      qxks() {
        // alert('取消成功!');
        // console.log(this.timeObject);
        // console.log('11111111111111111111111111');

        // return false;

        this.$confirm('是否结束考试?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'SUCCESS'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '取消成功!'
          });
          this.getScore1();
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消成功！'
          // });
        });

      },
      //定时
      valChange(){
        // console.log(this.timeObject);
         // this.seconds -= 1
         // this.countDown()
      },

      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
      },

      qh(it) {
        console.log(it);
        this.v = it;
      },
    },
    // beforeDestory(){
    //   window.clearTimeout(this.timeObject);
    // },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice,
      Journalism,
      List,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    display: flex;
    justify-content: space-between;
    background: #eff3f6;
    padding: 1.875rem;
    min-height: 100vh;

    .l-hang {
      background: #dedede;
      width: 100%;
      height: 0.125rem;
    }

    .left {
      width: 25%;
      background: #fff;
      font-size: 0.875rem;
      padding: 0.825rem;
      border-radius: 0.325rem;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
      height: 93vh;

      .tit {
        margin-bottom: 0.3rem !important;
        margin-top: 0.5rem;
        font-size: 18px;
      }

      .l-top {
        margin-bottom: 1.875rem;
      }

      .l-title {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1.875rem;
      }

      .l-content {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 0.9375rem;

        .one {
          cursor: pointer;
          width: 12%;
          border: 1px solid #dedede;

          margin-bottom: 0.625rem;
          margin: 0 2.33%;
          margin-bottom: 0.625rem;

          a {
            width: 100%;
            height: 100%;
            padding: 0.4375rem 0;
            display: inline-block;
          }

        }
      }

      .l-shijian {
        margin-bottom: 1.875rem;
        margin-top: 1.875rem;
        display: flex;
        align-items: center;

        .shijian {
          font-size: 1.4375rem;
          color: red;
        }
      }

      .anniu {
        display: flex;
        justify-content: space-around;
        margin-top: 1.875rem;
        flex-wrap: wrap;

        div {
          color: #fff;
          background: #50a0ff;
          padding: 0.5375rem 1.875rem;
          border-radius: 0.2125rem;
          font-size: 0.525rem;
          cursor: pointer;
          margin-bottom: 1.875rem;

        }

        .qxks {
          background: #979696;
        }

        .tjsj {}
      }
    }


    .right {
      width: 73%;
      background: #fff;
      font-size: 0.875rem;
      padding: 0;
      border-radius: 0.325rem;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
      height: 93vh;
      padding-bottom: 1.875rem;
      overflow-y: auto;

      .r-top {
        font-size: 1.3rem;
        padding: 0.875rem;
      }

      .r-two {
        padding: 1.125rem;
        padding-bottom: 0.5rem !important;

        .r-t-title {
          font-size: 1rem;
          margin-bottom: 0.625rem;
        }

        .xuanxiang {
          font-size: 0.95rem;
          position: relative;
          .zz{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .xx {
            // margin-bottom: 0.625rem;
          }
        }
      }


    }

  }

  .el-checkbox {
    width: 100%;
  }

  .bs {
    background: var(--el-color-primary) !important;

    a {
      color: #fff !important;
    }

  }

  .zysx {
    font-size: 19px;
    color: red;
    font-weight: bold;
  }
  .jeshi{
    margin: 10px;
    font-size: 12px;
    color:red;
  }
  .fs{
     margin: 10px;
  }
  .ff{
    display: flex;
    align-items: center;
    margin: 11px 0;
    .df{
      width: 50px;
      padding-right: 10px;
      text-align: right;

    }
  }
</style>
