<template>
  <div class="top-control">
    <el-container>
      <el-footer >
        <div class="h-center">
           <div class="h-cen">
              <div class="h-c-one">友情链接：</div>
              <div class="h-c-one gd1" @click="curl(item.curl)" v-for="item in list">{{item.name}}<span>|</span></div>
   <!--           <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div>
              <div class="h-c-one">药咖会<span>|</span></div> -->
          <!--   <div @click="goTo('/','')" :class="top==1?'bs':''" class="h-c-one">首页</div>
             <div @click="goTo('/about','')" :class="top==2?'bs':''" class="h-c-one">关于联盟</div>
             <div @click="goTo('/new','')"  :class="top==3?'bs':''" class="h-c-one">新闻资讯</div>
             <div @click="goTo('/member','')" :class="top==4?'bs':''" class="h-c-one">会员体系</div>
             <div @click="goTo('/forum','')" :class="top==5?'bs':''" class="h-c-one">会议论坛</div>
             <div @click="goTo('/expert','')" :class="top==6?'bs':''" class="h-c-one">专家库</div>
             <div @click="goTo('/financing','')" :class="top==7?'bs':''" class="h-c-one">投融资</div>
             <div @click="goTo('/work','')" :class="top==8?'bs':''"  class="h-c-one">党建工作</div>
             <div @click="goTo('/cooperation','')" :class="top==9?'bs':''" class="h-c-one">合作交流</div>
             <div @click="goTo('/us','')" :class="top==10?'bs':''" class="h-c-one">联系我们</div> -->

           </div>
         </div>

        <div class="h-top">
         <div class="t-top">
           <div class="t-left">
              <img class="tp" @click="goTo('/')" src="~assets/home/logo2.png" />
              <div class="list">
                <div class="l-one">
                  <div class="l-bt">
                    <span class="gd"  @click="goTo('/about','')">关于联盟</span>
                    <div class="qhs" v-show="one" @click="one=false">
                      <img  src="~assets/home/xiala.png" />
                    </div>
                    <div class="qhs" v-show="!one" @click="one=true" >
                      <img src="~assets/home/xialaf.png" />
                    </div>

                  </div>
                  <div class="m-list"  v-show="!one">
                    <div class="gd" @click="goTo('/about','0','1')" >联盟介绍</div>
                    <div class="gd" @click="goTo('/about','1','1')" >领导简介</div>
                    <div class="gd" @click="goTo('/about','2','1')" >组织结构</div>
                  </div>
                </div>

                <div class="l-one">
                  <div class="l-bt">
                    <span class="gd" @click="goTo('/us','')" >联系我们</span>
                    <div class="qhs" v-show="two" @click="two=false">
                      <img  src="~assets/home/xiala.png" />
                    </div>
                    <div class="qhs" v-show="!two" @click="two=true" >
                      <img src="~assets/home/xialaf.png" />
                    </div>

                  </div>
                  <div class="m-list" v-show="!two">
                    <div class="gd" @click="goTo('/us','0','1')">平台简介</div>
                    <div class="gd" @click="goTo('/us','1','1')">联盟地址</div>
                    <div class="gd" @click="goTo('/us','2','1')">隐私协议</div>
                  </div>
                </div>


                <div class="l-one">
                  <div class="l-bt">
                   <span class="gd" @click="goTo('/cooperation','')" >合作交流</span>
                   <div class="qhs" v-show="three" @click="three=false">
                     <img  src="~assets/home/xiala.png" />
                   </div>
                   <div class="qhs" v-show="!three" @click="three=true" >
                     <img src="~assets/home/xialaf.png" />
                   </div>
                  </div>
                  <div class="m-list" v-show="!three">
                    <div class="gd" @click="goTo('/cooperation','')" >技术成果库</div>
                    <div class="gd" @click="goTo('/merchant','')">需求商机</div>
                  </div>
                </div>


                <div class="l-one">
                  <div class="l-bt">
                    <span class="gd" @click="goTo('/expert','')">专家库</span>              
                    <div class="qhs" v-show="four" @click="four=false">
                      <img  src="~assets/home/xiala.png" />
                    </div>
                    <div class="qhs" v-show="!four" @click="four=true" >
                      <img src="~assets/home/xialaf.png" />
                    </div>


                  </div>
                  <div class="m-list" v-show="!four">
                    <div class="gd" @click="goTo('/expert','0','1')">专家顾问库</div>
                    <div class="gd" @click="goTo('/expert','1','1')">企业医疗机构库</div>
                  </div>
                </div>

              </div>
              <div class="rx">客服服务热线：</div>
              <div class="dh"><img src="~assets/home/dh.png" />{{shezhi.phone}}</div>
              <div class="rx1">{{shezhi.kaddress}}</div>

              <div class="r-one mobile">
                <div class="r-c">
                  <img :src="'https://ymedicine.jiujingwulian.com/public'+shezhi.gimage" />
                </div>
                <div class="r-t">官方公众号</div>
              </div>

           </div>
           <div class="t-right">
              <div class="r-one">
                <div class="r-t gd" @click="goTo('/about','')" >关于联盟</div>
                <div class="r-c">
                  <div class="r-c-u gd" @click="goTo('/about','0','1')">联盟介绍</div>
                  <div class="r-c-u gd" @click="goTo('/about','1','1')">领导简介</div>
                  <div class="r-c-u gd" @click="goTo('/about','2','1')">组织结构</div>
                </div>
              </div>
              <div class="xian"></div>
              <div class="r-one">
                <div class="r-t gd" @click="goTo('/expert','')">专家库</div>
                <div class="r-c">
                  <div class="r-c-u gd" @click="goTo('/expert','0','1')">专家顾问库</div>
                  <div class="r-c-u gd" @click="goTo('/expert','1','1')">企业医疗机构库</div>
                </div>
              </div>
               <div class="xian"></div>
              <div class="r-one">
                <div class="r-t gd" @click="goTo('/cooperation','')">合作交流</div>
                <div class="r-c">
                  <div class="r-c-u gd" @click="goTo('/cooperation','')">技术成果库</div>
                  <div class="r-c-u gd" @click="goTo('/merchant','')">需求商机</div>
                </div>
              </div>
              <div class="xian"></div>
              <div class="r-one">
                <div class="r-t gd" @click="goTo('/us','')">联系我们</div>
                <div class="r-c">
                  <div class="r-c-u gd" @click="goTo('/us','0','1')">平台简介</div>
                  <div class="r-c-u gd" @click="goTo('/us','1','1')">联盟地址</div>
                  <div class="r-c-u gd" @click="goTo('/us','2','1')">隐私协议</div>
                </div>
              </div>
               <!-- <div class="xian"></div> -->
              <div class="r-one">
                <div class="r-t">官方公众号</div>
                <div class="r-c">
                  <img :src="'https://ymedicine.jiujingwulian.com/public'+shezhi.gimage" />
                </div>
              </div>


            </div>

         </div>

         <div class="shuxian"></div>
         <div class="wz">Copyright C 2022 All Rights Reserved 版权所有丨技术支持：玖鲸互联<br/>
          <span @click="tiaozhuan()">苏ICP备20011661号-3</span></div>
<!--  -->
        </div>
      </el-footer>
       <!-- -->

    </el-container>

  </div>
</template>

<script>

  import {useRouter} from 'vue-router';
  import {
    getfyqlj
  } from 'network/foot.js';

  import {
    getfshezhi
  } from 'network/home.js';


  export default{
    name:'Foot',
    data() {
         return {
           shezhi:{},
           one:true,
           two:true,
           three:true,
           four:true,


           list:[]
         }
     },

    props: ["top"],
    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,fl,lx)=>{
        if(lx==1){
          var query={fl:fl}
        }else{
          var query={id:fl}
        }

        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }


      return {goTo}

    },
    mounted() {


       this.listdata();
       this.fshezhi();
     },
    methods: {
      tiaozhuan(){
        window.location.href='https://www.miit.gov.cn/';
      },
      fshezhi(){
        var that = this;
        var data = {

        };
        getfshezhi(data).then(res => {
          console.log('zd');
          console.log(res);
          that.shezhi=res.data.data;
        }).catch(err => {})
      },
      listdata(){
        var that = this;
        var data = {

        };
        getfyqlj(data).then(res => {

          that.list=res.data.data;

        }).catch(err => {})
      },
      curl(curl){
        // window.location.href=curl;
        window.open(curl);
      }
    }
  }
</script>

<style lang="scss" scoped="scoped">
  .el-footer{
    padding: 0;
  }
  .h-top{
    width: 100vw;
    // min-height: 10vh;
    background: url(assets/home/dibu.png) no-repeat;
    background-size: 100% 100%;
    .t-top{
      width: var(--allWidth);
      margin: 0 auto;
      font-size: 0.875rem;
      padding-top: 3.75rem;
      display: flex;
      .t-left{
        .tp{
          width: 15.625rem;
          margin-bottom: 2.875rem;
          cursor: pointer;
        }
        .rx{
          color: #fff;
          font-size: 0.9rem;
          margin-bottom: 1.875rem;
        }
        .dh{
          display: flex;
          align-items: center;
          font-size: 1.65rem;
          color: #fff;
          img{
            margin-right: 0.6rem;
          }
          margin-bottom: 1.875rem;
        }
        .rx1{
          color: #fff;
          font-size: 0.6rem;
          margin-bottom: 1.875rem;
        }
      }
      .t-right{
        margin-left: 12.25rem;
        display: flex;
        align-items: top;
        color: #fff;
        .xian{
          width: 0.0625rem;
          background: #fff;
          height: 40%;
        }
        .r-one{

          .r-t{
            font-size: 1rem;
            cursor: pointer;
          }
          .r-c{
            margin-top: 1.875rem;
            font-size: 0.8rem;
            .r-c-u{
              margin-bottom: 0.625rem;
              cursor: pointer;
            }
            img{
              width: 8.25rem;
            }
          }
          margin:0 2.6rem;
        }

      }

    }

    .shuxian{
      width: 100%;
      background: #fff;
      height: 0.065rem;
      margin: 1.25rem 0;
    }
    .wz{
      text-align: center;
      color:#fff;
      font-size: 0.8125rem;
      line-height: 1.3rem;
      padding-bottom: 1.25rem;
    }
  }

  .h-center{
    width: 100vw;
    // background: #C5F4FF;
    background: linear-gradient(to left,#C5F4FF,#C9FFF5);
    color: #000000;
    .h-cen{
      width: var(--allWidth);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .bs{
          background: #0976B2;
      }
      .h-c-one{
        padding: 1.25rem 0rem;
        width: auto;
        text-align: center;
        font-size: 0.8125rem;

        cursor: pointer;
        span{
           padding: 0rem 0.5rem;
        }

      }
    }
  }
  .list{
    display: none;
  }
  .mobile{
    display: none;
  }

  @media screen and (max-width: 1300px) {
    .mobile{
      display: block;
    }
    .h-cen{
      padding: 0 10px;
      width: 100%!important;
      flex-wrap: wrap;
      padding: 0.5rem;
      .h-c-one{
        padding: 0.5rem 0!important;
      }
    }
    .h-top{
      .t-top{
        width: 100%;
        padding: 0 0.75rem;
        padding-top: 1.75rem;
        .t-left{
          position: relative;
          width: 100%;
          .tp{
            margin-bottom: 1rem;
          }
          .list{
            display: block;
            width: 100%;
            .l-one{
              color: #fff;
              padding: 0.625rem;
              border-bottom: 0.0625rem solid #fff;
              .l-bt{
                font-size: 1rem;
                font-weight: bold;
                position: relative;
                .qhs{
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translate(0,-50%);
                  width: 50%;
                  text-align: right;
                }
                img{
                  width: 1.225rem;

                }
              }

              .m-list{
                margin: 0.625rem;
                div{
                  font-size: 0.9rem;
                  margin-bottom: 0.6rem;
                }
              }

            }
          }

          .rx{
            margin-top: 1.875rem;
            font-size: 1.1rem;
            font-weight: bold;
          }
          .rx1{
            font-size: 1.1rem;
          }
          .r-one{

            width: auto;
            position: absolute;
            bottom: 0.625rem;
            right: 0.625rem;
            text-align: center;
            width: 6rem;
            .r-c{
              width: 100%;
              margin: 0 auto;
              img{
                width: 100%;
              }
            }
            .r-t{
              color: #fff;
              font-size: 1rem;
              margin-top: 0.2rem;
            }

          }
        }
        .t-right{
          display: none;

        }
      }
    }

  }

  .gd:hover{
    color: #c5f5fe!important;
  }
  .gd1:hover{
    color: #2E9BD6!important;
  }

</style>
