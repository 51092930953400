<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff" :xx="xx"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft" :types="types" :leftlist="leftlist" :oneList="oneList"></MyLeft>

        <div class="c-right">
          <div class="list">
            <list :know="know" :know1="know1" :know2="know2" @page="getdatapage($event)" :type='0' ></list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


 import {
    getmessageall
  } from 'network/my.js';


 import {
    getoneList,
    getjsknow,
    getshoucang
  } from 'network/examination.js';


  import 'element-ui/lib/theme-chalk/index.css';
  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import List from "views/examination/ChildComps/List.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";
  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";
  export default {
    name: 'My',
    data() {
      return {
        jsknowlist_id:0,
        oneList:[],
        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',
        xx:1,
        know:[],
        know1:[],
        know2:[],
        types:0,
        zt: 0,
        // shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft:0,//左边
        leftlist:[],
        message:[],
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        leftlist:[],
        grxx: {},
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query,id) => {
        // 项目:0=会议,1=论坛,2=技术成果库,3=需求商机,4=投融资
        if(query=='0'){
          path='';
        }else if(query=='1'){
          path='';
        }else if(query=='2'){
          path='';
        }else if(query=='3'){
          path='';
        }else if(query=='4'){
          path='';
        }
        var list={
          id:id,
        }
        router.push({
          path: path,
          query: list || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },



  watch: {
      // 方法1
      '$route'(to, from) { //监听路由是否变化
        console.log(this.$route.params);
        if (this.$route.params.articleId) { // 判断条件1  判断传递值的变化
          //获取文章数据
        }
      },
      //方法2
      '$route'(to, from) {
        console.log(to);
        if (to.path == "/") { /// 判断条件2  监听路由名 监听你从什么路由跳转过来的

          var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
          url = '?' + url;

          console.log('jlls');
          var object = {};
          if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
          {
            var str = url.substr(1); //得到?后面的字符串
            var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
            for (var i = 0; i < strs.length; i++) {
              object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
            }
          }
          sessionStorage.setItem("page",0);
          this.page=sessionStorage.getItem("page");
          console.log(object);
          // var query=window.location.search.substring(1);
          var id = object['id'];
          this.topleft = id;
          this.getdate(id);

        }
      },
    },

    created(){
      this.page=sessionStorage.getItem("page");
      this.getdate(this.jsknowlist_id);
    },

    mounted() {
      // var cid='1,2,';
      // cid=cid.substring(0,cid.length-1)
      // console.log(cid);
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);


      var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
      url = '?' + url;

      console.log(url);
      console.log('jql');
      var object = {};
      if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      {
        var str = url.substr(1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
        }
      }
      console.log(object);

      this.topleft=object['id'];
      // sessionStorage.setItem("page",0);
      var lx = object['lx'];

      // this.zt = lx;
      // this.getdata(this.zt);
      this.left1();
       // console.log(this.leftlist);
    },


    methods: {
      shoucang(id,index){
        this.know[index]['is_ok']=true;

         // const h = this.$createElement;
         this.$notify({
            // title: '提示',
            type: 'success',
            message: '收藏成功',
             offset: 60
          });

        var that = this;
        var data = {
          id:id,
          type:0
        };
        getshoucang(data).then(res => {

        }).catch(err => {})


      },
      qxshoucang(id,index){
        this.know[index]['is_ok']=false;
        // const h = this.$createElement;
        this.$notify({
           // title: '提示',
          type: 'success',
           message: '取消收藏成功',
            offset: 60
         });

        var that = this;
        var data = {
          id:id,
          type:1
        };
        getshoucang(data).then(res => {

        }).catch(err => {})


      },

       left1(){
          var that = this;
          var data = {
          };          getoneList(data).then(res => {

                console.log('123');
                that.oneList=res.data;

                //退出登录
                console.log(that.oneList);
                if(that.oneList.code=='0001'){
                  window.location.href="http://viki.js-exp.com/public/uPqdLSmiBQ.php/index/login";
                }
                if(!that.topleft){
                  that.getdate(that.oneList[0]['jsknowlist'][0]['id']);
                }else{
                  that.getdate(that.topleft);
                }

                // that.topleft=res.data[0]['id'];
          }).catch(err => {})

       },
       getdatapage(event){
         console.log(event);
         // alert(event);
         sessionStorage.setItem("page",event);

         this.page=event;
         this.getdate(this.jsknowlist_id);
       },      getdate(id){
        var that = this;
        this.jsknowlist_id=id;
        this.topleft=id;
        var data = {
          page:this.page,
          id:id
        };
        getjsknow(data).then(res => {
          console.log(res);
          console.log('ppp');
          this.know=res.data.table.data;
          this.know1=res.data;
          this.know2=res.data.table;
          console.log(this.know);
        }).catch(err => {})

      },


      left(){
        var that = this;
        var data = {
        };
        getJspaper(data).then(res => {
          console.log(res);
          that.leftlist=res.data;
        }).catch(err => {})

      },







      listcount(page){
        this.page=page;
        this.getdata(this.zt);
      },


      getdata(lx){

        console.log(this.grxx.id);
        console.log('jll');

        var that = this;

        var data = {
          status:lx,
          uid:this.grxx.id,
          page:this.page,
          sl:8
        };
        getmessageall(data).then(res => {

          // console.log('zx');
          // console.log(res);
          // that.message=res.data.message;

        that.message=res.data.message.table.data;
        that.total=res.data.message.table.total;
        that.per_page=res.data.message.table.per_page;
        that.current_page=res.data.message.table.current_page;
        that.last_page=res.data.message.table.last_page;
        that.items=res.data.message.items;
        that.litems=res.data.message.litems;





        }).catch(err => {})
      },


      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      qh(zts){
        this.zt=zts;
        this.page=0;
        this.getdata(zts);
      },
      fanhui() {
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Page,
      Null,
      List,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    min-height: 100vh;
    .c-all {
      width: var(--allWidth);
      width: 98%;
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 84%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding:0 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }
          .zhongjian{
            display: inline-block;
            margin-left: 16rem;
            div{
               display: inline-block;
               width: 4.875rem;
               padding: 1.275rem 0rem;
               text-align: center;
               margin: 0 2rem;
               cursor: pointer;
            }
            .zs{
              border-bottom: 0.125rem solid #2E9BD6;
            }
          }
          span {
            vertical-align: middle;
          }
        }
        .list{
        }
      }
    }
  }
 .shoucang{
    display: flex!important;
    height: 0.625rem;
  }

  @media screen and (max-width: 1300px) {
      .l-one{
        .img{
          width:100%!important;
        }
      }
     .c-all{
       width: 95%!important;
       margin: 0 auto;
       flex-wrap: wrap;
     }
     .c-right{
       width: 100%!important;
       margin: 0 auto!important;
     }

     .lefts{
       width: 100%;
     }

     .c-right{
      .center{
           margin: 0rem !important;
           padding: 0.75rem!important;
      }
     }

     .c-bt{
       display: flex;
     }
     .c-lt{
       padding: 0.65rem 0!important;
       width: 36%;
       text-align: center;
       margin-bottom: 0.625rem;
     }
     .c-right{
       .c-top{
         padding: 0.8rem!important;
         .zhongjian{
           margin: 0!important;
           padding: 0;
           display: flex!important;
           .z-one{
             padding: 0.675rem 0rem!important;
             width: 60%!important;
             font-size: 0.95rem!important;
             margin: 0!important;
             margin-bottom: 0.625rem!important;
           }
         }
       }
     }
    .l-one{
      flex-wrap: wrap;
      .l-left{
        width: 100%!important;
      }
      .l-right{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }



  }

 .lll{
    display: flex;
    align-items: center;
    margin-top: 0.425rem;

    .lll-list{
      display: flex;
      // align-items: center;
      font-size:0.8rem;
      margin-right: 0.825rem!important;
      color:#888;
      img{
        width: 0.8rem!important;
        height: 0.8rem!important;
        margin-right: 0.125rem!important;
      }
    }
  }
  .zxr{

    font-size:0.7rem;
    margin-top: 0.425rem;

    .pepolist{
       display: flex;
       .pepo{
         text-align: center;
         margin-top: 0.625rem;
         margin-right: 0.625rem;
       }
       img{
         font-size:0.7rem;
         width: 2rem!important;
         height: 2rem!important;
         margin-right: 0.125rem!important;
       }

    }

  }
 .pi2{
    display: none!important;
  }

  .l-one:hover{
      // background: #064addd6;
      background: rgb(36 88 201 / 81%);
      color: #fff;
      .l-n-nr{
        color: #fff!important;
      }
      .lll-list{
        color: #fff!important;
      }
      .pi2{
        display: inline-block!important;
      }
      .pi1{
        display: none!important;
      }
  }

 .pi2{
    display: none!important;
  }


    .img{
      width: 21%;
      img{
        width: 100%;
      }
    }
    .l-left{
      width: 77% !important;
      margin-left: 1.5%;
    }

</style>
