import { request } from "./request.js";



export function getJspaperpd(params){
    return request(
      {
          url:'/public/api/examination/getJspaperpd',
          method:'POST',
          params:params
      }
    )


}


export function getJspaper(params){
    return request(
      {
          url:'/public/api/examination/getJspaper',
          method:'POST',
          params:params
      }
    )


}
export function getshoucang(params){
    return request(
      {
          url:'/public/api/index/shoucang',
          method:'POST',
          params:params
      }
    )


}





export function getoneList(params){
    return request(
      {
          url:'/public/api/index/oneList',
          method:'POST',
          params:params
      }
    )
}

export function getjsknow(params){
    return request(
      {
          url:'/public/api/index/jsknow',
          method:'POST',
          params:params
      }
    )
}


export function getJspaperIn(params){
    return request(
      {
          url:'/public/api/examination/getJspaperIn',
          method:'POST',
          params:params
      }
    )
}
export function getJspaperIn1(params){
    return request(
      {
          url:'/public/api/examination/getJspaperIn1',
          method:'POST',
          params:params
      }
    )
}

export function getScore(params){
    return request(
      {
          url:'/public/api/examination/getScore',
          method:'POST',
          params:params
      }
    )
}
// 批卷
export function getScorepijuan(params){
    return request(
      {
          url:'/public/api/examination/getScorepijuan',
          method:'POST',
          params:params
      }
    )
}





export function getfaboutshezhi(params){
    return request(
      {
          url:'/public/api/examination/in',
          method:'POST',
          params:params
      }
    )
}

export function getfaboutshezhi1(params){
    return request(
      {
          url:'/public/api/examination/in1',
          method:'POST',
          params:params
      }
    )
}


/**
 * 排行
 */
export function getpaiHang(params){
    return request(
      {
          url:'/public/api/index/paiHang',
          method:'POST',
          params:params
      }
    )
}

/**
 * 排行
 */
export function gettuiJian1(params){
    return request(
      {
          url:'/public/api/index/tuiJian',
          method:'POST',
          params:params
      }
    )
}
/**
 * 排行
 */
export function gethotCi(params){
    return request(
      {
          url:'/public/api/index/hotCi',
          method:'POST',
          params:params
      }
    )
}

/**
 * 我的收藏
 */
export function getcollectList(params){
    return request(
      {
          url:'/public/api/index/collectList',
          method:'POST',
          params:params
      }
    )
}

/**
 * 知识库详情
 */
export function getin(params){
    return request(
      {
          url:'/public/api/index/jsknowin',
          method:'POST',
          params:params
      }
    )
}


/**
 * 关键词
 */
export function getguanjianci(params){
    return request(
      {
          url:'/public/api/index/guanjianci',
          method:'POST',
          params:params
      }
    )
}






