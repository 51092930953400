<template>

  <view class="top-control">
    <view class="logo">
      <img  src="../../../assets/my/cc.png"/>
      <view class="one">
        <view class="one-x" :class="xx==1?'bs':''" @click="goTo('/','')" >知识库</view>
        <view class="one-x" :class="xx==3?'bs':''"  @click="goTo('collect','')">我的收藏</view>
        <view class="one-x" :class="xx==4?'bs':''"  @click="goTo('search','')">搜索知识</view>
        <!-- <view class="one-x" :class="xx==5?'bs':''"  @click="goTo('search2','')">搜索知识</view> -->

        <view class="one-x" :class="xx==2?'bs':''"  @click="goTo('examination','')">在线考试</view>

        <!-- <view class="one-x">产品荟</view>
        <view class="one-x">客服系统</view> -->
        <!-- <view>
          <img  src="../../../assets/my/tx.png"/>
          <view>你猜</view>
        </view> -->

      </view>

      <!-- <view class="sousuo">
         <el-input class="shuru" v-model="name" placeholder="请输入" />
          <el-button class="anniu bs1" type="primary" >搜索</el-button>
      </view> -->
    </view>
  </view>

</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  import {
    setCookie,
    getCookie,
    clearCookie
  } from "components/common/common.js";


  import {
    getnew,
    getgrxx,
    getfshezhi
  } from 'network/home.js';

  export default {
    name: 'Head',
    data() {
      return {
        // xx:1,
        bol:false,
        sh:false,
        o1:true,
        o2:true,
        o3:true,
        o4:true,
        o5:true,
        o6:true,
        o7:true,
        o8:true,
        o9:true,

        shezhi: {},
        grxx: [],
        sousuo: '',
        newfl: []
      }
    },
    props: ["top", 'qhs', 'sousu', 'nr', 'grxxff','xx'],
    setup() {
      //跳转方法
      const router = useRouter();
      // var sousuo=this.sousuo;
      // this.sousuo=this.sousu;
      var that = this;
      const goTo = (path, fl, lx) => {
        console.log(lx);
        console.log(fl);
        if (lx == 1) {
          var query = {
            fl: fl
          }
        } else if (lx == 2) {
          sessionStorage.setItem("sousuo", fl); //存储
          var query = {
            sousuo: fl
          }
        } else {
          var query = {
            id: fl
          }
        }

        console.log(query);
        console.log(path);
        router.push({
          path: path,
          query: query || {}
        })

      }
      const goTo1 = (path, zt) => {
        var query = {
          zt: zt
        }
        router.push({
          path: path,
          query: query || {}
        })
      }




      return {
        goTo,
        goTo1
      }

    },
    mounted() {
      // this.getfl();
      // var ss = sessionStorage.getItem('sousuo');
      // if (ss === 'undefined') {
      //   ss = '';
      // }
      // this.sousuo = ss ? ss : '';

      // var uid = getCookie();
      // // sessionStorage.getItem('uid');
      // if (uid === 'undefined') {
      //   uid = '';
      // }

      // // if (uid) {
      //   this.getgrxx1(uid);
      // // }


      // // sessionStorage.getItems("sousuo");
      // console.log(this.sousuo);
      // console.log(this.sousu);
      // console.log('ss');
      // this.fshezhi();
    },
    methods: {
      beacklogin() {

        ElMessageBox.confirm(
            '是否确认退出登录?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '退出成功',
            })
            clearCookie();
            sessionStorage.setItem("uid", '');
            this.grxx = [];
            this.grxxff([]);

            this.$router.push('/login')




          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })


      },
      getgrxx1(uid) {
        var that = this;
        var data = {
          uid: uid
        };
        getgrxx(data).then(res => {
          console.log('zd');
          console.log(res.data.data);
          that.grxx = res.data.data;
          if(that.grxx.name==''){
            that.getgrxx2();
          }else{
            that.grxxff(that.grxx);
          }

        }).catch(err => {})

      },
      getgrxx2() {
        var that = this;
        var uid = sessionStorage.getItem('uid');
        var data = {
          uid: uid
        };
        getgrxx(data).then(res => {
          console.log('asdasd');
          console.log(res);
          console.log(uid);
          that.grxx = res.data.data;
          if(that.grxx.name==''){

          }
          that.grxxff(that.grxx);
        }).catch(err => {})

      },

      getgrxx3(uid) {
        var that = this;
        // var uid = sessionStorage.getItem('uid');
        var data = {
          uid: uid
        };
          console.log('授权的登录冲'+uid);
        getgrxx(data).then(res => {
          console.log('授权的登录冲');
          console.log(res);
          that.grxx = res.data.data;
          if(that.grxx.name==''){

          }
          that.grxxff(that.grxx);
        }).catch(err => {})

      },

      fshezhi() {
        var that = this;
        var data = {

        };
        getfshezhi(data).then(res => {
          console.log('zd');
          console.log(res);
          that.shezhi = res.data.data;

        }).catch(err => {})
      },
      // sousuonew(){
      qhs() {

        sessionStorage.setItem("sousuo", this.sousuo); //存储
        // sessionStorage.getItems("sousuo");//按可以进行取值
        this.nr(this.sousuo);
      },
      // },
      getfl() {
        var that = this;
        var data = {
          type: 0,
          limit: 100
        };
        getnew(data).then(res => {
          that.newfl = res.data.list;
        }).catch(err => {})

      },

      qh(num) {
        this.qhs(num);
      }
    }

  }
</script>

<style lang="scss" scoped="scoped">
  .bs{
    border-bottom: 2px solid #fff;
  }
  .sousuo{
    display: flex;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0%, -50%);
  }
  .top-control{
    width: 100%;
    background: #064add;
    position: sticky;
    top: 0;
    z-index: 200;
    .logo{


        // position: relative;
        background: #064add;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding:0.5rem 1rem;
        img{
          width: 7.3rem;
          // border-radius: 50%;
        }
    }
    .one{


      margin-left: 0.2rem;
      color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .one-x{
        width: 4rem;
        text-align: center;
        padding-bottom: 0.4rem;
        margin: 0 1rem;
      }
    }
  }

  body{
    overflow-y: hidden;
  }
  .bbs {
    margin-right: 0.25rem !important;
  }

  .ys {
    color: #AFC81D;
    margin-right: 1.25rem !important;
  }

  .h-top {
    width: 100vw;
    min-height: 10vh;
    background: url(assets/home/top.png) no-repeat;
    background-size: 100% 100%;

    .t-top {
      background: rgba(255, 255, 255, 0.4);
      padding: 0.625rem 0.3125rem;

      .t-center {
        width: var(--allWidth);
        margin: 0 auto;
        font-size: 0.875rem;

        .t-span {
          color: rgb(113, 113, 113);
          display: inline-block;
        }

        .right {
          span {
            margin-right: 0.625rem;
            cursor: pointer;
          }
        }
      }
    }

    .t-cen {
      width: var(--allWidth);
      margin: 0 auto;
      padding: 1.875rem 0;
      display: flex;
      align-items: center;

      .t-img1 {
        width: 20vw;
        margin-right: 2vw;
        cursor: pointer;
      }

      .t-img2 {
        width: 10vw;
        cursor: pointer;
      }

      position: relative;

      .sousuo {
        position: absolute;
        top: 50%;
        right: 1.125rem;
        transform: translate(0, -50%);
        width: 15vw;
        display: flex;

        input {
          width: 70%;
          padding: 0.75rem 0.625rem;
          border-radius: 1.875rem;
          border: 0.125rem solid #AFC81D;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 0.8125rem;
          outline: 0;
        }

        .sanniu {
          width: 30%;
          background: #AFC81D;
          font-size: 0.8em;
          padding: 0.75rem 0.3125rem;
          border: 0.125rem solid #AFC81D;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;

          img {
            margin-right: 3px;
          }

          cursor: pointer;
        }

      }
    }
  }

  .h-center {
    width: 100vw;
    background: #2E9BD6;
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;

    .h-cen {
      width: var(--allWidth);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .bs {
        background: #0976B2;
      }

      .h-c-one:hover {

        background: #0976B2;

        .chijing {
          display: block !important;
          height: auto;

          overflow: hidden;
        }

      }

      .one:hover {
        background: #0976B2 !important;
      }


      .h-c-one {
        padding: 1.25rem 0;
        width: 9.125rem;
        text-align: center;
        font-size: 1rem;

        cursor: pointer;
        position: relative;

        span {
          padding: 1.25rem 2.5rem;
          height: 100%;
          width: 100%;
        }

        .chijing {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          overflow-y: hidden;
          transform: translate(0, 100%);

          .one {
            padding: 1.25rem 0;
            background: #2E9BD6;
          }
        }

        .chijing {
          // display: none;
          height: 0;
          // transition: all 3s;
          // transition-property:height;
          // transition-duration: 2s;
          // animation:height 3s;
          border-bottom-left-radius: 0.575rem;
          border-bottom-right-radius: 0.575rem;
        }
      }

    }
  }
  .shouji{
    display:none;
  }

  @media screen and (max-width: 1300px) {
    .one{
      width: 100%!important;
      justify-content:space-between!important;
      .one-x{
          padding:0.425rem  0!important;
          margin: 0!important;
      }
    }
    .logo{
      flex-wrap: wrap;
      img{
        width: 6.3rem!important;
      }
      .one{
           margin-top: 0.325rem;
           width: 19.5rem;
           flex-wrap: wrap;
           font-size: 0.8rem;

        flex-wrap: wrap;
        .one-x{
          margin-bottom: 0.425rem;
          width: auto;
          padding: 0px 0.6125rem;
        }
      }
    }
    .sousuo{
      position: static!important;
      width: 100%;
      right: 1rem;
      top: 50%;
      transform: translate(0%, 0%)!important;
      margin-top: 0.625rem;
      .shuru{
        margin-right: 0.9375rem;
      }
    }

    .bbs{
      font-size: 0.8rem!important;
    }
    .ys{
      margin-right: 0.4rem !important;
      font-size: 0.8rem!important;
    }
    .h-top,
    .h-center {
      display: none;
    }
    .shouji{
       display: block;
       .shou-top{
         background: rgba(255,255,255,0.1);
         padding: 0.375rem;
         display: flex;
         align-items: center;
         justify-content: center;
         .shou-title{
           max-width: 40%;
           font-size: 0.65rem;
           line-height: 1.2rem;
         }
         .opp{
            font-size: 0.65rem;
            margin-left: 0.5rem;
            background: #318FC6;
            color: #fff;
            padding:0.5rem 0.3125rem;
            border-radius: 0.3125rem;
            width: 6rem;
            text-align: center;
         }
       }
       .xm{
         .dwss{
          position: absolute;
          top: 15%;
          right: 5%;
          width: 2.75rem;
         }
         width: 100%;
         background: url(assets/home/top.png) no-repeat;
         background-size: 100% 100%;
         padding: 1.175rem 0.6rem 4.5rem 0.6rem;
         position: relative;
         .t-img1{
            width: 49%;
         }
         .t-img2{
            width: 28%;
         }


         .sousuo {
           position: absolute;
           bottom: 0.625rem;
           left: 4%;
           width: 92%;
           display: flex;
           align-items: center;
           .right{
             margin-top: 0!important;
          }
           input {
             width: 70%;
             padding: 0.75rem 0.625rem;
             border-radius: 1.875rem;
             border: 0.125rem solid #AFC81D;
             border-right: 0;
             border-top-right-radius: 0;
             border-bottom-right-radius: 0;
             font-size: 0.8125rem;
             outline: 0;
           }

           .sanniu {
             width: 30%;
             background: #AFC81D;
             font-size: 0.8em;
             padding: 0.75rem 0.3125rem;
             border: 0.125rem solid #AFC81D;
             border-top-right-radius: 30px;
             border-bottom-right-radius: 30px;
             display: flex;
             align-items: center;
             justify-content: center;
             color: #fff;
             text-align: center;

             img {
               margin-right: 3px;
             }

             cursor: pointer;
           }

         }



       }


    }

    .m-bj{
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999999999;
    }
    .m-left{
      width: 60%;
      height: 100%;
      background: #fff;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99999999999;
      .m-list{
        padding: 1.25rem;
        .m-one{
          font-size: 1rem;
          padding:0.8rem 1.25rem;
          border-bottom: 0.0625rem solid #EBEBEB;
          .m-tit{
            position: relative;
            img{
              width: 1rem;
              position: absolute;
              right:0;
              top:50%;
              transform: translate(0,-50%);
            }
          }

        }
        .m-xlist{
          .m-x-one{
            text-align: center;
            padding: 0.875rem 0;
            background: #EFF5F9;
          }
        }

      }
    }



  }
  .right>span:hover{
    color: #2E9BD6;
  }

  .fade-enter-active {
    animation: bounce-in 6s linear;
    animation-fill-mode:both;
  }
  // .fade-leave-active {
  //   animation: bounce-in 0.5s reverse;
  // }
  @keyframes bounce-in {
    from {
     height: 0;
    }

    to {
      height: auto;
    }
  }
  /* 类名要对应回 name 的属性值 */
  .moveCartoon-enter-active {
      animation: move 1s;
  }
  .moveCartoon-leave-active {
      animation: move 1s reverse;
  }

  @keyframes move {
      from {
          transform: translateX(-100%);
      }
      to {
          transform: translate(0);
      }
  }

</style>
